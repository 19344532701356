<template>
    <div class="bg">
        <div class="main text-center">
            <!--<img src="../assets/image/ys/img/title.png" class="title " alt="">-->
            <div class="main_con" style="padding-top: 200px;padding-bottom: 100px">
                <ul>
                    <li>
                        <a   href="http://ccsc.9zhinet.com/xyadmin/login/main" target="_blank" >
                            <img src="../assets/image/ys/img/xinyun.png" alt="">
                            <img src="../assets/image/ys/img/xy.png" alt="" class="icon">
                        </a>
                    </li>
                    <li>
                        <a  href="http://ccsc.9zhinet.com/cloudanalysis/login/main"   target="_blank" >
                            <img src="../assets/image/ys/img/jyingfenxi.png" alt="">
                            <img src="../assets/image/ys/img/jyfx.png"  alt="" class="icon">
                        </a>
                    </li>
                    <li>
                        <a href="http://ccsc.9zhinet.com/cardsystem/login/index" target="_blank" >
                            <img src="../assets/image/ys/img/yikatong.png" alt="">
                            <img src="../assets/image/ys/img/ykt.png"  alt="" class="icon">
                        </a>
                    </li>
                    <li>
                        <a href="http://ccsc.9zhinet.com/cloudCustomerServiceV2/login/main" target="_blank" >
                            <img src="../assets/image/ys/img/yunkefu.png" alt="">
                            <img src="../assets/image/ys/img/ykf.png"  alt="" class="icon">
                        </a>
                    </li>
                    <li>
                        <a href="http://ccsc.9zhinet.com/bankMgr/login/index" target="_blank"   >
                            <img src="../assets/image/ys/img/qjspt.png" alt="">
                            <img src="../assets/image/ys/img/qjs.png"  alt="" class="icon">
                        </a>
                    </li>
                </ul>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "loginIndex"
    }
</script>

<style scoped>
    *{margin: 0;padding: 0;}
    ul,li{
        list-style: none;
    }

    body,html{min-height: 100%;}
    .bg{
        background: url(../assets/image/ys/img/bgimg.png) no-repeat center top;
        /* background-size: 100% 100%; */
    }
    .main{
        width: 1000px;
        margin:0 auto;
    }
    .text-center{
        text-align: center;
    }
    .title{
        display: inline-block;
        margin-bottom: 60px;
        margin-top: 120px;
    }
    .main_con ul li{
        float: left;
        width: 33.333%;
        text-align: center;
        margin-bottom: 20px;
    }
    .main_con ul{
        overflow: hidden;
    }
    .main_con li a{
        display: inline-block;
        position: relative;
        overflow: hidden;
        height: 145px;
        max-width: 270px;
        box-shadow: 5px 5px 3px rgba(0,0,0,0.2);

    }
    .box-shadow{
        box-shadow: 5px 5px 3px rgba(0,0,0,0.2);
    }
    .main_con li a:hover .icon{
        color:#f900b0;opacity:.2;
        -webkit-transform:scale(20deg) scale(2);-moz-transform:rotate(20deg) scale(2);-ms-transform:rotate(20deg) scale(2);transform:rotate(20deg) scale(2);
    }
    .icon{
        position: absolute;
        left: 17px;
        bottom: 17px;
        width: 78px;
        -webkit-transition: all 300ms linear;
        -moz-transition: all 300ms linear;
        -o-transition: all 300ms linear;
        -ms-transition: all 300ms linear;
        transition: all 300ms linear;
    }
</style>
